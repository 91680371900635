// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complaints-procedure-js": () => import("./../../src/pages/complaints-procedure.js" /* webpackChunkName: "component---src-pages-complaints-procedure-js" */),
  "component---src-pages-contact-us-js": () => import("./../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-no-win-no-fee-js": () => import("./../../src/pages/no-win-no-fee.js" /* webpackChunkName: "component---src-pages-no-win-no-fee-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

